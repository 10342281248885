<template>
	<v-toolbar flat dark color="primary">
		<v-tooltip v-if="canEdit && !editTitle" bottom>
			<template v-slot:activator="{ on }">
				<v-btn v-if="!editTitle" :disabled="!isTaskTitleEditable" icon flat v-on="on" @click="editTitle = true">
					<v-icon color="light grey">edit</v-icon>
				</v-btn>
			</template>
			<span v-t="'actions.edit'" />
		</v-tooltip>
		<v-toolbar-title text-truncate>
			<v-layout v-if="!editTitle" column>
				<v-flex text-truncate v-text="taskTitle" />
				<v-flex v-if="isAccountant" font-italic caption v-text="taskSubTitle" />
			</v-layout>
			<v-layout v-else>
				<v-text-field v-model="model.title" :maxlength="191" :label="$t('tasks.title')" color="white" />
			</v-layout>
		</v-toolbar-title>
		<v-spacer />
		<SelectWithChips
			v-model="model.status"
			dense
			persistent-hint
			list-with-chips
			:label="$t('client_workspace.task.status')"
			:items="statuses"
			:item-value="'id'"
			:item-text="'name'"
			return-object
			open-on-clear
			color="white"
			prepend-icon="play_arrow"
			:max-selected-items-showed="1"
		/>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn :disabled="!canSave" flat icon v-on="on" @click="saveTask">
					<v-icon>save</v-icon>
				</v-btn>
			</template>
			<span v-t="'client_workspace.task.save'" />
		</v-tooltip>
		<w-btn-delete v-if="canEdit" :loading="deletionLoading" mini :text="$t('client_workspace.task.delete')" @click="deleteTask()"> </w-btn-delete>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn flat icon round transition @click="$emit('close')" v-on="on">
					<v-icon>close</v-icon>
				</v-btn>
			</template>
			<span v-t="'close'" />
		</v-tooltip>
	</v-toolbar>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsToolbar',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		originalTask: {
			required: true,
			type: Object
		},
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			deletionLoading: false,
			editTitle: false,
			modified: false
		}
	},
	computed: {
		...mapState({
			statuses: state => state.tasks.statuses,
			tasks: state => state.tasks.list,
			isAccountant: state => state.auth.isAccountant
		}),
		isInternal: function () {
			return this.task && !this.task.vendor
		},
		isTaskTitleEditable: function () {
			return !this.$te(this.task.title)
		},
		taskTitle: function () {
			return this.isTaskTitleEditable ? this.task.title : this.$tc(this.task.title, 2)
		},
		taskSubTitle: function () {
			return this.isInternal
				? this.$t('client_workspace.tasks.internalType')
				: this.task.vendor.clientCode
				? this.task.vendor.clientCode
				: this.task.vendor.company
		},
		canEdit: function () {
			return this.service.actionsRules.canEdit(this.task)
		},
		canSave: function () {
			return this.modified
		},
		model: {
			get: function () {
				return this.task
			},
			set: function (newValue) {
				this.$emit('task-update', newValue)
			}
		}
	},
	watch: {
		task: {
			deep: true,
			immediate: true,
			handler: function () {
				this.modified = Object.keys(this.getModifiedFields()).length > 0
			}
		}
	},
	methods: {
		getModifiedFields: function () {
			let result = {}
			if (this.originalTask.title != this.task.title) {
				result.title = this.task.title
			}
			if (this.originalTask.description != this.task.description) {
				result.description = this.task.description
			}
			if (this.originalTask.note != this.task.note) {
				result.note = this.task.note
			}
			if (this.originalTask.due_date != this.task.due_date) {
				result.due_date = this.task.due_date.substring(0, 10)
			}
			if (this.originalTask.status.id != this.task.status.id) {
				result.status = this.task.status
			}
			if (this.originalTask.type != this.task.type) {
				result.type = this.task.type
			}
			if (
				this.originalTask.themes.length != this.task.themes.length ||
				this.originalTask.themes
					.map(theme => theme.id)
					.sort()
					.toString() !=
					this.task.themes
						.map(theme => theme.id)
						.sort()
						.toString()
			) {
				result.themes = this.task.themes
			}
			if (
				this.originalTask.members.length != this.task.members.length ||
				this.originalTask.members
					.map(member => member.id)
					.sort()
					.toString() !=
					this.task.members
						.map(member => member.id)
						.sort()
						.toString()
			) {
				result.members = this.task.members
			}
			return result
		},
		saveTask: function () {
			const updatedTaskFields = {
				id: this.task.id
			}
			Object.assign(updatedTaskFields, this.getModifiedFields())
			this.service
				.updateTask(updatedTaskFields)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('tasks.update.success'))
				})
				.catch(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('tasks.update.error'))
				})
		},
		deleteTask: function () {
			this.$dialog
				.warning({
					text: this.$t('client_workspace.task.delete_confirm'),
					title: this.$t('client_workspace.task.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.deletionLoading = true
						return this.service
							.deleteTask(this.task)
							.then(() => {
								let ref
								if (this.task && this.task.isChild) {
									const parentTask = this.service.retrieveParentTaskInList(this.task, this.tasks)
									if (parentTask) {
										this.service.selectTask(parentTask)
										ref = parentTask.children
									}
								} else {
									ref = this.tasks
									this.service.selectTask(null)
								}
								if (ref) {
									ref.splice(ref.indexOf(ref.find(task => task.id == this.task.id)), 1)
								}
								this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('tasks.delete.success'))
								this.eventBus.emit(this.events.TASK_DELETED)
							})
							.catch(() => {
								this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('tasks.delete.error'))
							})
							.finally(() => {
								this.deletionLoading = false
							})
					}
				})
		}
	}
}
</script>

<style scoped>
.v-toolbar__title {
	width: 100%;
}
</style>
